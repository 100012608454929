/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { RequestBodyData } from '../../../models/request-body.data';
import { StudyUpdateInterface } from '../../../models/interface/study/study_update.interface';
import { StudyCreationInterface } from '../../../models/interface/study/study_creation.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Study API service that handles study-related requests
 */

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API User - All Studies
   * This function retrieves all studies.
   *
   * @params string type  - A string determining the type of the study typically 'studies'.
   *         @DEPRECATED string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         string include - A string including additional information.
   *           - typically 'questionnaires', 'collaborators', 'owners' or 'members'
   *         number organisation_id - A number determines which organisation the study belongs to
   * @return Observable<any> - An observable for any response.
   */
  public getAllStudies(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    include?: string,
    organisation_id?: number
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(
      type,
      interventionType,
      isShareable,
      isSubscribable,
      isPrivate,
      undefined,
      include,
      undefined,
      undefined,
      undefined,
      organisation_id
    );
    const url = `${this.backendUrl}/api/v1/ecoach/studies?limit=0`;
    const options = { headers: this.header, observe: 'response', params } as const;
    return this.http.get<any>(url, options);
  }

  /**
   * AAS2 API User - Study Detail
   * This function retrieves details of a study.
   *
   * @params number studyId - ID of the study.
   *         string include - A string including additional information.
   *           - typically 'questionnaires', 'collaborators' or 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getStudyDetail(studyId: number, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(undefined, undefined, undefined, undefined, undefined, undefined, include);
    const url = `${this.backendUrl}/api/v1/ecoach/studies/${studyId}`;
    const options = { headers: this.header, observe: 'response', params } as const;
    return this.http.get<any>(url, options);
  }

  /**
   * AAS2 API User - Subscribe to Study
   * This function subscribes user to a study. (May need code) - Only if study is active and not private!
   *
   * @params number studyId - ID of the study.
   *         string code - A string to join the study.
   * @return Observable<any> - An observable for any response.
   */
  public subscribeStudy(studyId: number, code?: string): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          code
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/studies/${studyId}/subscribe`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Unsubscribe from Study
   * This function unsubscribes user from a study.
   *
   * @params number studyId - ID of the study.
   * @return Observable<any> - An observable for any response.
   */
  public unsubscribeStudy(studyId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/studies/${studyId}/unsubscribe`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Collaborating Studies
   * This function retrieves all isCollaboratingSubject questionnaires of the user.
   *
   * @params string type  - A string determining the type of the study typically 'studies'.
   *         @DEPRECATED string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_active - A number determines if study is active.
   *         number studyId - ID of the study.
   *         string include - A string including additional information.
   *           - typically 'owners' or 'roles'
   *         string role - A string to filter studies based on study roles/permissions
   * @return Observable<any> - An observable for any response.
   */
  public getCollaboratingStudies(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    studyId?: number,
    include?: string,
    role?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(
      type,
      interventionType,
      isShareable,
      isSubscribable,
      isPrivate,
      isActive,
      include,
      undefined,
      studyId,
      role
    );
    const url = `${this.backendUrl}/api/v1/ecoach/studies/collaborating?limit=0`;
    const options = { headers: this.header, observe: 'response', params } as const;
    return this.http.get<any>(url, options);
  }

  /**
   * AAS2 API ECoach - Non-Collaborating Studies
   * This function retrieves all non-isCollaboratingSubject questionnaires of the user.
   *
   * @params string type  - A string determining the type of the study typically 'studies'.
   *         @DEPRECATED string intervention_type - A string that determines whether the study is guided or unguided.
   *           - typically 'accompanied' or 'unaccompanied'
   *         number is_shareable - A number determines if study is shareable.
   *         number is_subscribable - A number determines if study is subscribable.
   *         number is_private - A number determines if study is private.
   *         number is_running - A number determines if study is running.
   *         number is_active - A number determines if study is active.
   *         string include - A string including additional information.
   *           - typically 'owners'
   * @return Observable<any> - An observable for any response.
   */
  public getNonCollaboratingStudies(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isRunning?: boolean,
    isPrivate?: number,
    isActive?: number,
    include?: string
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(type, interventionType, isShareable, isSubscribable, isPrivate, isActive, include, isRunning);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/noncollaborating?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Create Study
   * This function creates a new study.
   *
   * @params string name - A string determining the name of the study.
   *         string type - A string that determines the type of the group.
   *           - typically 'studies' or 'organisationgroups'
   *         number owner_id - ID of a user.
   *         number organisation_id - ID of an organisation.
   * @return Observable<any> - An observable for any response.
   */
  public createStudy(name: string, type: string, ownerId?: number, organisationId?: number): Observable<any> {
    const payload: PayloadInterface = this.createStudyBody(name, type, ownerId, organisationId);
    return this.http.post<any>(`${this.backendUrl}/api/v1/studies`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API Admin - Create Study
   * This function creates a new study.
   *
   * @params string name - A string determining the name of the study.
   *         string type - A string that determines the type of the group.
   *           - typically 'studies' or 'organisationgroups'
   *         number owner_id - ID of a user.
   *         number organisation_id - ID of an organisation.
   * @return Observable<any> - An observable for any response.
   */
  public createStudyAdmin(name: string, type: string, ownerId: number, organisationId?: number): Observable<any> {
    const payload: PayloadInterface = this.createStudyBody(name, type, ownerId, organisationId);
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/studies`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Update Study
   * This function updates a study.
   *
   * @params number studyId - ID of a study.
   *         string name - A string of the study name.
   *         Array<string> languages - A list of string for language.
   *         Array<any> translation_content - A list of content translated according to the languages.
   *         string access_type - A string determines how to access the study.
   *           - typically 'password' or 'invite'
   *         string password - A string for password.
   *         number is_private - A number determines if study is private.
   *         number starts_at - A number/timestamp for start.
   *         number ends_at - A number/timestamp for end.
   *         number is_sharable - A number determines if study is sharable.
   *         number is_unsubscribable - A number determines if study is unsubscribable.
   *         number is_running - A number determines if study is running.
   *         number is_active - A number determines if study is active.
   *         string picture - A path to an existing picture.
   * @return Observable<any> - An observable for any response.
   */
  public updateStudy(
    studyId: number,
    name?: string,
    languages?: Array<string>,
    translationContent?: Array<any>,
    accessType?: string,
    password?: string,
    isPrivate?: boolean,
    startsAt?: number,
    endsAt?: number,
    isShareable?: boolean,
    isUnsubscribable?: number,
    isRunning?: boolean,
    isActive?: number,
    picture?: string
  ): Observable<any> {
    const studyDetails: StudyUpdateInterface = {
      // Needed - private = 1 => accesstype: either password or invite => password not null in case of password
      name,
      locales: languages,
      is_private: isPrivate,
      accesstype: accessType,
      password,
      translations: translationContent,
      starts_at: startsAt,
      ends_at: endsAt,
      is_shareable: isShareable,
      is_unsubscribable: isUnsubscribable,
      is_running: isRunning,
      is_active: isActive,
      picture
    };

    for (const prop in studyDetails) {
      if (Object.prototype.hasOwnProperty.call(studyDetails, prop)) {
        switch (prop) {
          case 'name': {
            if (name === undefined) {
              delete studyDetails.name;
            }
            break;
          }
          case 'locales': {
            if (languages === undefined) {
              delete studyDetails.locales;
            }
            break;
          }
          case 'translations': {
            if (translationContent === undefined) {
              delete studyDetails.translations;
            }
            break;
          }
          case 'starts_at': {
            if (startsAt === undefined) {
              delete studyDetails.starts_at;
            }
            break;
          }
          case 'ends_at': {
            if (endsAt === undefined) {
              delete studyDetails.ends_at;
            }
            break;
          }
          case 'is_private': {
            if (isPrivate === undefined) {
              delete studyDetails.is_private;
            }
            break;
          }
          case 'accesstype': {
            if (accessType === undefined) {
              delete studyDetails.accesstype;
            }
            break;
          }
          case 'password': {
            if (password === undefined) {
              delete studyDetails.password;
            }
            break;
          }
          case 'is_shareable': {
            if (isShareable === undefined) {
              delete studyDetails.is_shareable;
            }
            break;
          }
          case 'is_unsubscribable': {
            if (isUnsubscribable === undefined) {
              delete studyDetails.is_unsubscribable;
            }
            break;
          }
          case 'is_running': {
            if (isRunning === undefined) {
              delete studyDetails.is_running;
            }
            break;
          }
          case 'is_active': {
            if (isActive === undefined) {
              delete studyDetails.is_active;
            }
            break;
          }
          case 'picture': {
            if (picture === undefined) {
              delete studyDetails.picture;
            }
            break;
          }
          default:
            break;
        }
      }
    }
    return this.http.patch<any>(`${this.backendUrl}/api/v1/studies/${studyId}`, new RequestBodyData('studies', studyDetails), {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * @Deprecated
   * AAS2 API User - Activate Study
   * This function activates a study.
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public activateStudy(studyId: number): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/studies/${studyId}/activate`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Delete Study
   * This function deletes a study.
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public deleteStudy(studyId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/studies/${studyId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Get Collaborators
   * This function returns all collaborators of a study.
   *
   * @params number studyId - ID of a study.
   *         string include - A string including additional information.
   *           - typically 'roles'
   * @return Observable<any> - An observable for any response.
   */
  public getCollaborators(studyId: number, include?: string): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/collaborators?limit=0`, { observe: 'response', params });
  }

  /**
   * AAS2 API Admin - Get Collaborators of a Study as Admin
   * This function returns all collaborators of a study as admin.
   *
   * @params number studyId - ID of a study.
   *         string include - A string including additional information.
   *           - typically 'roles'
   *         string searchTerm - A string to search for name and email of the user
   * @return Observable<any> - An observable for any response.
   */
  public getCollaboratorsAdmin(studyId: number, include?: string, searchterm?: string): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (searchterm !== undefined) {
      params = params.set('searchterm', searchterm);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/admin/studies/${studyId}/collaborators?limit=0`, { observe: 'response', params });
  }

  /**
   * AAS2 API User - Add Collaborators
   * This function adds collaborators to a study.
   *
   * @params number studyId - ID of a study.
   *         PayloadInterface payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public addCollaborators(studyId: number, payload: PayloadInterface): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/studies/${studyId}/relationships/collaborators`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Remove Collaborators
   * This function deletes collaborators from a study.
   *
   * @params number studyId - ID of a study.
   *         PayloadInterface payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public deleteCollaborators(studyId: number, payload: PayloadInterface): Observable<any> {
    return this.http.request<any>(`delete`, `${this.backendUrl}/api/v1/studies/${studyId}/relationships/collaborators`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach Manager - Get Members
   * This function returns all members of a study.
   *
   * @params number studyId - ID of a study.
   *         string include - A string including additional information.
   *           - typically 'studies'
   * @return Observable<any> - An observable for any response.
   */
  public getMembers(studyId: number, include?: string): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}/members?limit=0`, {
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get Members of an ecoach's study - restricted access ecoach/user
   * This function returns all ecoach's members of a study (related with instance).
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public getEcoachMembers(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/members?limit=0`, { observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Add Members
   * This function adds members to a study by sending an invitation.
   *
   * @params number studyId - ID of a study.
   *         PayloadInterface payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public addMembers(studyId: number, payload: PayloadInterface, language?: string): Observable<any> {
    this.header = language ? this.header.set('Accept-Language', language) : this.helperService.setLocaleFromStorage(this.header);
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/invite`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Update Member (Code)
   * This function updates member of a study.
   * NEW: Updates the code of a pending study => user_id and code have to be provided
   *
   * @params number studyId - ID of a study.
   *         PayloadInterface payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public updateMember(studyId: number, payload: PayloadInterface): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/studies/${studyId}/relationships/members`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Update Members Study Code
   * This function updates member study codes of a study.
   *
   * @params number studyId - ID of a study.
   *         PayloadInterface payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public updateMemberCode(studyId: number, payload: PayloadInterface): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/codes`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Remove Members
   * This function deletes member of a study.
   *
   * @params number studyId - ID of a study.
   *         PayloadInterface payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public deleteMembers(studyId: number, payload: PayloadInterface): Observable<any> {
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}/members`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Delete Study Codes of users with pending invitation
   * This function deletes the study codes of each user with pending invitation.
   *
   * @params number studyId - ID of a study.
   *         number userId - ID of a user.
   * @return Observable<any> - An observable for any response.
   */
  public deleteMemberCodeOfPendingInvitation(studyId: number, userId: number): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'studies',
        attributes: {
          users: [userId]
        }
      }
    };
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/ecoach/studies/${studyId}/codes`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Editor Get Pending Requests
   *
   * @Deprecated
   * This function returns all pending requests of a study.
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public getPendingRequests(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/editor/studies/${studyId}/requests?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get study invitations - restricted access to study.ecoach
   * This function returns all ecoach's study invitations.
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public getEcoachStudyInvitations(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/invitations?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Get all pending study invitations - restricted access to study.ecoachmanager and study.owner
   * This function returns all study invitations.
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public getAllPendingStudyInvitations(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}/invitations/pending?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Study Detail and its translations
   * This function retrieves details of a study and its translations.
   *
   * @params number studyId - ID of a study.
   *         string include - A string including additional information.
   *           - typically 'questionnaires', 'collaborators', 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getStudyDetailAndTranslation(studyId: number, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Upload media to study
   * This function uploads media to study.
   *
   * @params number studyId - ID of a study.
   *         File filetoUpload - Contains a media file.
   * @return Observable<any> - An observable for any response.
   */
  public uploadStudyMedia(studyId: number, filetoUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('files[]', filetoUpload);
    return this.http.post<any>(`${this.backendUrl}/api/v1/studies/${studyId}/media`, formData, { reportProgress: true, observe: 'events' });
  }

  /**
   * AAS2 API User - Get media of study
   * This function returns media of a study.
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public getStudyMedia(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/media`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Delete media of study
   * This function deletes media of study.
   *
   * @params number studyId - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public deleteStudyMedia(studyId: number, mediaIds: Array<number>) {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          ids: mediaIds
        }
      }
    };
    return this.http.delete<any>(`${this.backendUrl}/api/v1/studies/${studyId}/media`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Add/Update Members Study Code of an invitation
   * This function updates member study codes of a study invitation.
   *
   * @params number invitationId - ID of a study invitation.
   *         object payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public addUpdateMemberCode(invitationId: number, codeParam: string): Observable<any> {
    const codeBody = {
      code: codeParam !== undefined ? codeParam : null
    };
    return this.http.patch<any>(
      `${this.backendUrl}/api/v1/ecoach/invitations/${invitationId}/codes`,
      new RequestBodyData('studies', codeBody),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach Manager - Add/Update Members Study Code of an invitation
   * This function updates member study codes of a study invitation.
   *
   * @params number invitationId - ID of a study invitation.
   *         object payload - Contains a list of users.
   * @return Observable<any> - An observable for any response.
   */
  public addUpdateMemberCodeEM(invitationId: number, codeParam: string): Observable<any> {
    const codeBody = {
      code: codeParam !== undefined ? codeParam : null
    };
    return this.http.patch<any>(
      `${this.backendUrl}/api/v1/ecoachmanager/invitations/${invitationId}/codes`,
      new RequestBodyData('studies', codeBody),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach Manager - Resend study invitation as eCoach manager
   * This function resends any study invitation as eCoach manager.
   *
   * @params number studyId - ID of a study.
   *         Array<number> invitation_param - IDs of study invitations.
   * @return Observable<any> - An observable for any response.
   */
  public resendStudyInvitationEM(studyId: number, invitationsParam: Array<number>): Observable<any> {
    let invitation;
    if (invitationsParam !== undefined) {
      invitation = invitationsParam;
    } else {
      invitation = null;
    }
    const invitationBody = {
      ids: invitation
    };
    return this.http.post<any>(
      `${this.backendUrl}/api/v1/ecoachmanager/studies/${studyId}/invitations/pending/resend`,
      new RequestBodyData('invitations', invitationBody),
      { headers: this.header, observe: 'response' }
    );
  }

  /**
   * AAS2 API ECoach - Delete pending study invitation as eCoach
   * This function deletes a study invitation and the instances.
   *
   * @params number invitationId - ID of a study invitation.
   * @return Observable<any> - An observable for any response.
   */
  public deleteStudyInvitation(invitationId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoach/invitations/${invitationId}/pending`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Delete pending study invitation as eCoach manager
   * This function deletes a study invitation and the instances.
   *
   * @params number invitationId - ID of a study invitation.
   * @return Observable<any> - An observable for any response.
   */
  public deleteStudyInvitationEM(invitationId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoachmanager/invitations/${invitationId}/pending`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Delete code in pending study invitation as eCoach
   * This function deletes a study code of invitation.
   *
   * @params number invitationId - ID of a study invitation.
   * @return Observable<any> - An observable for any response.
   */
  public deleteCodeOfStudyInvitation(invitationId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoach/invitations/${invitationId}/codes`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Delete code in pending study invitation as eCoach manager
   * This function deletes a study code of invitation.
   *
   * @params number invitationId - ID of a study invitation.
   * @return Observable<any> - An observable for any response.
   */
  public deleteCodeOfStudyInvitationEM(invitationId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoachmanager/invitations/${invitationId}/codes`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Assign study to an organisation
   * This function assigns a study without an organisation to one.
   *
   * @params number studyId - ID of a study.
   *         number organisationId - ID of an organisation.
   * @return Observable<any> - An observable for any response.
   */
  public assignStudyToOrganisation(studyId: number, organisationId: number): Observable<any> {
    const organisationBody = {
      organisation_id: organisationId
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/admin/studies/${studyId}`, new RequestBodyData('studies', organisationBody), {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get activities of studies - restricted access ecoach
   * This function returns all activities of all ecoach's studies.
   * If the user is `study.owner` or `study.ecoachmanager`, all the study and instance related activities are returned.
   * If the user is `study.ecoach`, all the study activities and their own intervention instance-related activities are returned.
   * If the user is neither `study.owner`, `study.ecoachmanager` or `study.ecoach`, only the study activities are returned.
   *
   * @params number studyId - ID of a study.
   *         string include - A string including additional information.
   *           - typically 'questionnaires'
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesOfStudies(studyId?: number, include?: string): Observable<any> {
    let params = new HttpParams();
    if (studyId !== undefined) {
      params = params.set('study_id', studyId);
    }
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  public setHttpParams(
    type?: string,
    interventionType?: string,
    isShareable?: number,
    isSubscribable?: number,
    isPrivate?: number,
    isActive?: number,
    include?: string,
    isRunning?: boolean,
    studyId?: number,
    role?: string,
    organisation_id?: number
  ): HttpParams {
    let params = new HttpParams();
    if (type !== undefined) {
      params = params.set('type', type);
    }
    if (interventionType !== undefined) {
      params = params.set('intervention_type', interventionType.toString());
    }
    if (isShareable !== undefined) {
      params = params.set('is_shareable', isShareable.toString());
    }
    if (isSubscribable !== undefined) {
      params = params.set('is_subscribable', isSubscribable.toString());
    }
    if (isPrivate !== undefined) {
      params = params.set('is_private', isPrivate.toString());
    }
    if (isActive !== undefined) {
      params = params.set('is_active', isActive.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (isRunning !== undefined) {
      params = params.set('is_running', isRunning.toString());
    }
    if (studyId !== undefined) {
      params = params.set('id', studyId.toString());
    }
    if (role !== undefined) {
      params = params.set('role', role);
    }
    if (organisation_id !== undefined) {
      params = params.set('organisation_id', organisation_id);
    }
    return params;
  }

  public createStudyBody(name: string, type: string, ownerId: number, organisationId?: number): RequestBodyData {
    const study: StudyCreationInterface = {
      name,
      type,
      owner_id: ownerId,
      organisation_id: organisationId
    };
    for (const prop in study) {
      if (Object.prototype.hasOwnProperty.call(study, prop)) {
        switch (prop) {
          case 'owner_id': {
            if (ownerId === undefined) {
              delete study.owner_id;
            }
            break;
          }
          case 'organisation_id': {
            if (organisationId === undefined) {
              delete study.organisation_id;
            }
            break;
          }
          default:
            break;
        }
      }
    }
    return new RequestBodyData('studies', study);
  }

  /**
   * AAS2 API ECoach - Get Study Report
   * This function returns a report of a study in CSV. Given query parameters can be interpreted as booleans,
   * so 0 = FALSE and 1 = TRUE. If set to true, the data will be included in the report
   *
   * @params number studyId - ID of the study.
   *         number meta - Includes only the meta data of the study in report.
   *         number diaryInstance - Includes only the diary instances to the report; intervention instances are chosen by default.
   * @return Observable<any> - An observable for any response.
   */
  public getStudyCSVReportQueue(studyId: number, meta?: number, diaryInstances?: number): Observable<any> {
    let params = new HttpParams();
    if (meta !== undefined) {
      params = params.set('meta', meta.toString());
    }
    if (diaryInstances !== undefined) {
      params = params.set('diary_instances', diaryInstances.toString());
    }
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone !== undefined) {
      params = params.set('timezone', timezone);
    }
    return this.http.get(`${this.backendUrl}/api/v1/ecoach/queue/studies/${studyId}/csv/report`, {
      headers: this.header,
      reportProgress: true,
      observe: 'events',
      params
    });
  }

  /**
   * AAS2 API Admin - (Soft-)Delete a study
   * This function deletes a study.
   *
   * @params number studyId - ID of the study.
   * @return Observable<any> - An observable for any response.
   */
  public deleteStudyAdmin(studyId: number): Observable<any> {
    return this.http.request<any>(`delete`, `${this.backendUrl}/api/v1/admin/studies/${studyId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach Manager - Add collaborator to all intervention instances of another eCoach in the study
   * This function adds a collaborator (study.ecoach|study.ecoachmanager|study.owner) to all intervention
   * instances of another eCoach in a specific study.
   *
   * @params number newEcoach - ID of the eCoach to be added.
   *         number currentEcoach - ID of the target eCoach.
   *         number studyId - ID of the study.
   * @return Observable<any> - An observable for any response.
   */
  public addCollaboratorToAllInterventionInstancesOfECoach(newECoach: number, currentECoach: number, studyId: number): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'intervention_instance',
        attributes: {
          new_ecoach: newECoach,
          current_ecoach: currentECoach,
          study_id: studyId
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/ecoach`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }
}
